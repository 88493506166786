<template>
  <div>
    <van-form ref="form">
      <van-cell-group title="违纪审核信息">
        <dis-audit ref="disAudit" :app-params="{ coreKey: $route.query.id,  mkCode: 'DISCIPLINARY_MANAGE',coreCode:'DISCIPLINARY_MANAGE_AUDIT', status:$route.query.spzt }" />
      </van-cell-group>
      <!-- 基本信息 -->
      <van-cell-group title="基本信息">
        <van-field v-model="form.stuName" readonly label="姓名" />
        <van-field v-model="form.stuNo" readonly label="学号" />
        <van-field v-model="form.gradeNo" readonly label="年级" />
        <van-field v-model="form.unitName" readonly label="院系" />
        <van-field v-model="form.specName" readonly label="专业" />
        <van-field v-model="form.className" readonly label="班级" />
      </van-cell-group>
      <!-- 基本信息 -->
      <van-cell-group title="违纪信息">
        <van-field v-model="form.schoolYear" readonly label="学年" />
        <van-field v-model="form.issueNumber" readonly label="发文编号" />
        <van-field v-model="form.disciplinaryAt" readonly label="违纪时间" />
        <van-field v-model="form.confirmResult" readonly label="认定结果">
          <template #input>
            {{ codeConvertLabel(form.confirmResult,dict.disciplinary_punish_behavior)  }}
          </template>
        </van-field>
        <van-field v-model="form.punishResult" readonly label="处分结果">
          <template #input>
            {{ codeConvertLabel(form.punishResult,dict.disciplinary_punish_res)  }}
          </template>
        </van-field>
        <van-field v-model="form.punishAt" readonly label="处分期限">
          <template #input>
            {{ codeConvertLabel(form.punishAt,dict.disciplinary_punish_date)  }}
          </template>
        </van-field>
        <van-field v-model="form.disciplinarySituation" rows="3" class="textarea-style" arrow-direction="" readonly label="违纪情况说明" type="textarea" maxlength="300" />
      </van-cell-group>
      <!-- 处分材料 -->
      <van-cell-group title="处分材料" v-if="refsh1">
        <file-upload v-model="fileList1" :preview="true" accept=".doc,.docx,.pdf,.xls,.xlsx,.png,.gif,.jpeg" miz="JZGL" biz="storage" :multiple="3" list-type="list" :auto-upload="true" :max-size="1">
          <span slot="tip" class="upload-tips">
            支持上传任意文件,文件最大1M
          </span>
        </file-upload>
      </van-cell-group>
      <!-- 处分决定书 -->
      <van-cell-group title="处分决定书" v-if="refsh2">
        <file-upload v-model="fileList2" :preview="true" :multiple="3" list-type="list" :auto-upload="true" :max-size="1">
          <span slot="tip" class="upload-tips">
            支持上传任意文件,文件最大1M
          </span>
        </file-upload>
      </van-cell-group>
      <!-- 基本信息 -->
      <van-cell-group title="违纪撤销申请" v-if="form.isCancel">
        <van-field v-model="form.cancelRemark" rows="3" class="textarea-style" arrow-direction="" readonly label="申请理由" type="textarea" maxlength="300" />
      </van-cell-group>
      <!-- 审核信息 -->
      <van-cell-group title="撤销审核信息" v-if="form.isCancel">
        <audit ref="audit" :app-params="{ coreKey: $route.query.id,  mkCode: 'DISCIPLINARY_MANAGE',coreCode:'STU_CANCEL_DISCIPLINARY_AUDIT', status:$route.query.status,level:3}" />
      </van-cell-group>
    </van-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import audit from "@/view/modules/common/audit";
import disAudit from "@/view/modules/common/disAudit";
import { getDtoById } from '@/api/modules/daily/disciplinary'
import { codeConvertLabel } from "@/utils";
import fileUpload from "@/components/FileUpload";

export default {
  name: "DisciplinaryDeatil",
  dicts: ["disciplinary_punish_res", "disciplinary_punish_date", 'disciplinary_punish_behavior'],
  components: { audit, disAudit, fileUpload },
  data() {
    return {
      form: {

      },
      loading: false,
      fileList1: [],
      refsh1: false,
      fileList2: [],
      refsh2: false,
    }
  },
  watch: {
  },
  computed: {
    ...mapGetters(["user", "roles"]),
  },
  created() {
    this.$nextTick((e) => {
      getDtoById(this.$route.query.id).then((res) => {
        this.refsh1 = false
        this.refsh2 = false
        this.form = { ...res }
        if (res.files) {
          this.fileList1 = JSON.parse(res.files)
          this.refsh1 = true
        }
        if (res.materialFiles) {
          this.fileList2 = JSON.parse(res.materialFiles)
          this.refsh2 = true
        }
      })
    })
  },
  methods: {
    codeConvertLabel,
  }
}
</script>

<style lang="scss" scoped>
::v-deep.radio-other-info {
  .van-cell {
    .van-field__label {
      width: 11.2em;
    }
  }
}

.van-field__label {
  width: 110px;
}
.van-cell {
  font-size: 15px;
  line-height: 32px;
  height: 50px;
}
.textarea-style {
  height: 100%;
}
</style>